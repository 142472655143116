import React, { useEffect, useRef, useState } from "react";
import FooterPage from "../footer/footer";
import Header from "../header/header";
import NavigationPage from "../navigation-page/navigation-page";
import { CookiesProvider, useCookies } from "react-cookie";
import "./app.css";
import "../i18n";
import i18n from "../i18n";

function App() {
  const [cookies, setCookie] = useCookies(["appLanguage"]);
  const [language, setLanguage] = useState(cookies.appLanguage || "tr");
  let topButton: HTMLElement | null;
  const fixedSectionRef = useRef(null);

  useEffect(() => {
    setCookie("appLanguage", language, {
      path: "/",
      expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
    });
    i18n.changeLanguage(language);
  }, [language, setCookie]);

  window.onscroll = () => {
    topButton = document.getElementById("GoToTopButton");
    scrollFunction(topButton);
  };

  function scrollFunction(mybutton: HTMLElement | null) {
    if (mybutton) {
      if (
        document.body.scrollTop > 200 ||
        document.documentElement.scrollTop > 200
      ) {
        mybutton.style.display = "block";
      } else {
        mybutton.style.display = "none";
      }
    }
  }

  function backToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  return (
    <CookiesProvider>
      <div className="container-fluid m-0 p-0">
        <div ref={fixedSectionRef} className="row fixedSection m-0 p-0">
          <Header language={language} setLanguage={setLanguage}></Header>
        </div>
        <div className="row navigationPage p-0 mt-5 ms-0 mb-0 me-0">
          <NavigationPage></NavigationPage>
        </div>
        <div className="row p-0 m-0">
          <footer>
            <FooterPage></FooterPage>
          </footer>
        </div>
        <button
          id="GoToTopButton"
          className="rounded rounded-circle GoToTopButton"
          onClick={backToTop}
        ></button>
      </div>
    </CookiesProvider>
  );
}

export default App;
