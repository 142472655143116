import "./resume.css";
import Translate from "../resources";

function Resume() {
  return (
    <div className="container-fluid">
      <div className="row mt-4 align-items-center justify-content-center">
        <img
          className="img-fluid rounded-circle resumeimage"
          src={`/team-photos/gorkem_guner_300x300.webp`}
          alt="member"
          loading="lazy"
        ></img>
      </div>
      <p className=" resumeheader mt-2 text-center text-nowrap fw-bolder header">
        Dt. Görkem Güner
      </p>
      <p className="text-center text-nowrap fst-italic resumecontent">
        {Translate("endodontics")} - {Translate("partner")}
      </p>

      <div className="row m-1">
        <p className=" resumecontent header">
          {Translate("resumeTextGorkemGunerGeneral")}
        </p>
        <div className="row mt-2 mb-2">
          <p className=" resumeheader text-nowrap fw-bolder header">
            {Translate("resumeTextTreatments")}
          </p>
          <ul className="ms-5">
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextGorkemGunerTreatment1")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextGorkemGunerTreatment2")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextGorkemGunerTreatment3")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextGorkemGunerTreatment4")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextGorkemGunerTreatment5")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextGorkemGunerTreatment6")}</li>
            </p>
          </ul>
        </div>

        <div className="row mt-2 mb-2">
          <p className=" resumeheader text-nowrap fw-bolder header">
            {Translate("resumeTextAcedemic")}
          </p>
          <ul className="ms-5">
            <p className="m-1 resumecontent">
              <li> {Translate("resumeTextGorkemGunerAcademic1")}</li>
            </p>
          </ul>
        </div>

        <div className="row mt-2 mb-2">
          <p className=" resumeheader text-nowrap fw-bolder header">
            {Translate("resumeTextSeminars")}
          </p>
          <ul className="ms-5">
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextGorkemGunerSeminars1")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextGorkemGunerSeminars2")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextGorkemGunerSeminars3")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextGorkemGunerSeminars4")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextGorkemGunerSeminars5")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextGorkemGunerSeminars6")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextGorkemGunerSeminars7")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextGorkemGunerSeminars8")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextGorkemGunerSeminars9")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextGorkemGunerSeminars10")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextGorkemGunerSeminars11")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextGorkemGunerSeminars12")}</li>
            </p>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Resume;
