import Translate from "../resources";
import "./footer.css";

function FooterPage() {
  return (
    <div className="container-fluid">
      <div className="row footerrow rounded-2 p-2">
        <p className="footertext text-center mt-0 mb-0">
          {Translate("footerTextGeneral")}
        </p>
        <p className="footertext text-center pt-0 m-0 fw-bold fst-italic">
          {Translate("footerTextRightReserved")}
        </p>
        <a
          className="nav-link*underline text-center footertext"
          href={"/duodent_kvkk.pdf"}
          rel="noreferrer"
          target="_blank"
        >
          {Translate("footerTextPersonalInfoProtection")}
        </a>
      </div>
    </div>
  );
}

export default FooterPage;
