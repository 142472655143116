import "./resume.css";
import Translate from "../resources";

function Resume() {
  return (
    <div className="container-fluid">
      <div className="row mt-4 align-items-center justify-content-center">
        <img
          className="img-fluid rounded-circle resumeimage"
          src={`/team-photos/selena_su_denizer_300x300.webp`}
          alt="member"
          loading="lazy"
        ></img>
      </div>
      <p className=" resumeheader mt-2 text-center text-nowrap fw-bolder header">
        Dt. Selena Su Denizer
      </p>
      <p className="text-center text-nowrap fst-italic resumecontent">
        {Translate("pedodontics")}
      </p>
      <div className="row m-1">
        <p className=" resumecontent header">
          {Translate("resumeTextSelenaSuDenizerGeneral")}
        </p>
        <div className="row mt-2 mb-2">
          <p className=" resumeheader text-nowrap fw-bolder header">
            {Translate("resumeTextTreatments")}
          </p>
          <ul className="ms-5">
            <p className="m-1 resumecontent">{Translate("pedodontics")}</p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextSelenaSuDenizerTreatment1")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextSelenaSuDenizerTreatment2")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextSelenaSuDenizerTreatment2")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextSelenaSuDenizerTreatment3")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextSelenaSuDenizerTreatment4")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextSelenaSuDenizerTreatment5")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextSelenaSuDenizerTreatment6")}</li>
            </p>
          </ul>
        </div>

        <div className="row mt-2 mb-2">
          <p className=" resumeheader text-nowrap fw-bolder header">
            {Translate("resumeTextAcedemic")}
          </p>
          <ul className="ms-5">
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextSelenaSuDenizerAcademic1")}</li>
            </p>
          </ul>
        </div>

        <div className="row mt-2 mb-2">
          <p className=" resumeheader text-nowrap fw-bolder header">
            {Translate("resumeTextSeminars")}
          </p>
          <ul className="ms-5">
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextSelenaSuDenizerSeminars1")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextSelenaSuDenizerSeminars2")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextSelenaSuDenizerSeminars3")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextSelenaSuDenizerSeminars4")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextSelenaSuDenizerSeminars5")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextSelenaSuDenizerSeminars6")}</li>
            </p>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Resume;
