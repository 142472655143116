import { Outlet } from "react-router-dom";
import "./navigation-page.css";

function NavigationPage(): JSX.Element {
  return (
    <div>
      <Outlet></Outlet>
    </div>
  );
}

export default NavigationPage;
