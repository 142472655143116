import React, { useState, MouseEvent } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { styled } from "@mui/material/styles";
import { FlagIcon, FlagIconCode } from "react-flag-kit";

// Custom styled Menu component
const CustomMenu = styled(Menu)(() => {
  return {
    "& .MuiPaper-root": {
      backgroundColor: "#b39c86f0", // Change this to your desired background color
    },
  };
});

const CustomMenuItem = styled(MenuItem)(() => ({
  color: "#846d57", // Change this to your desired text color
}));

const LanguageSwitcher = ({
  TextSize,
  language,
  setLanguage,
}: {
  TextSize?: number;
  language: string;
  setLanguage: any;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (lang: string | null) => {
    setAnchorEl(null);
    if (lang) {
      setLanguage(lang);
    }
  };

  const languages = [
    { code: "en", label: "EN", countryCode: "GB" }, // Assuming you want UK flag for English
    { code: "tr", label: "TR", countryCode: "TR" },
  ];

  return (
    <div>
      <Button
        sx={{
          color: "#846d57",
          textDecoration: "none",
          fontSize: TextSize + "px",
        }}
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
      >
        {languages.find((lang) => lang.code === language)?.label}
      </Button>
      <CustomMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleClose(null)}
      >
        {languages
          .filter((lang) => lang.code !== language)
          .map((lang) => (
            <CustomMenuItem
              key={lang.code}
              onClick={() => handleClose(lang.code)}
            >
              <FlagIcon
                code={lang.countryCode as FlagIconCode} // Cast lang.countryCode to FlagIconCode
                size={16}
                style={{ marginRight: 8 }}
              />
              {lang.label}
            </CustomMenuItem>
          ))}
      </CustomMenu>
    </div>
  );
};

export default LanguageSwitcher;
