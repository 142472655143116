import LanguageSwitcher from "../header/languageswitcher";
import translate from "../resources";
import "./navbar.css";

const NavigationBar = ({
  SocialMediaIcons,
  Logo,
  language,
  setLanguage,
}: {
  SocialMediaIcons?: any;
  Logo?: any;
  language: string;
  setLanguage: any;
}) => (
  <nav className="navbar m-0 p-1 sticky-bottom navbar-expand-lg">
    <div className="col-1 p-0 m-0 align-content-center justify-content-center align-items-center text-center d-flex d-lg-none">
      <button
        className="navbar-toggler m-0 p-0 collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navCollapse"
        aria-controls="#navCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon m-0 p-0"></span>
      </button>
    </div>
    <div className="col-6 p-0 m-0 align-content-start justify-content-start align-items-start d-flex d-lg-none">
      {Logo}
      <LanguageSwitcher
        TextSize={14}
        language={language}
        setLanguage={setLanguage}
      ></LanguageSwitcher>
    </div>
    <div className="col-5 p-0 m-0 align-content-center justify-content-center align-items-center text-center d-flex d-lg-none">
      <div>{SocialMediaIcons}</div>
    </div>
    <div
      className="collapse m-0 p-0 navbar-collapse justify-content-center"
      id="navCollapse"
    >
      <ul className="navbar-nav m-0 p-0">
        <li className="nav-item">
          <a className="nav-link" href="/home">
            {translate("home")}
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/treatments">
            {translate("treatments")}
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/team">
            {translate("team")}
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/gallery">
            {translate("gallery")}
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/communication">
            {translate("communication")}
          </a>
        </li>
      </ul>
    </div>
  </nav>
);

export default NavigationBar;
