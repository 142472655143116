import "react-image-gallery/styles/css/image-gallery.css";
import "./gallery.css";
import ReactImageGallery from "react-image-gallery";
import { Box, Card } from "@mui/material";
import CardCover from "@mui/material/CardMedia";
import React from "react";

function Gallery() {
  const images: any[] = [];

  for (let i = 1; i <= 14; i++) {
    images.push({
      original: `/clinic-photos/image_${i}.webp`,
      thumbnail: `/clinic-photos/image_${i}_thumbnail.webp`,
    });
  }

  return (
    <div className="container-fluid">
      <Box
        component="ul"
        sx={{
          display: "flex",
          border: "none",
          gap: 2,
          flexWrap: "wrap",
          p: 2,
          m: 2,
          maxWidth: 1024,
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto",
        }}
      >
        <Card component="li" sx={{ minWidth: 300, flexGrow: 1 }}>
          <CardCover
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <iframe
              width="1024"
              height="768"
              title="YouTube video player"
              src="https://www.youtube.com/embed/kMskdHueznw?si=anCmLyXq1g4fBEu9"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </CardCover>
        </Card>
      </Box>

      <div className="row p-4">
        {
          <ReactImageGallery
            items={images}
            slideOnThumbnailOver={true}
            showPlayButton={false}
            showFullscreenButton={false}
            useBrowserFullscreen={false}
            lazyLoad={true}
          />
        }
      </div>
    </div>
  );
}

export default Gallery;
