import Translate from "../resources";
import "./communication.css";

function Communication({ color }: { color: string }) {
  const pStyle = { color: color };

  return (
    <div className="container-fluid">
      <div className="row text-center">
        <h2 className="mt-4 mb-4 text-nowrap fw-bolder header">
          {Translate("communication")}
        </h2>
        <div className="row justify-content-center text-center">
          <p className="addresslink fw-bolder" style={pStyle}>
            {Translate("contactTextDirection")}
          </p>
        </div>
        <div className="col-sm-6 col-xs-12">
          <div className="row mb-4">
            <p className="font-weight-bold font-italic header h5">
              <u>{Translate("contactTextPhone")}</u>
            </p>
            <div className="row justify-content-center">
              <div className="col-auto">
                <p className="font-weight-bold m-0">
                  {Translate("contactTextLandLine")}:
                </p>
              </div>
              <div className="col-auto ms-sm-1 ">
                <a id="phonecalltrigger" href="tel:90-216-766-7386">
                  +90 216 766 73 86
                </a>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-auto">
                <p className="font-weight-bold m-0">
                  {Translate("contactTextMobile")}:
                </p>
              </div>
              <div className="col-auto">
                <a id="phonecalltrigger" href="tel:90-505-766-7386">
                  +90 505 766 73 86
                </a>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <p className="font-weight-bold font-italic header h5">
              <u>E-Mail</u>
            </p>
            <a href="mailto:info@duodent.com.tr"> info@duodent.com.tr</a>
          </div>

          <div className="row mb-4">
            <p className="font-weight-bold font-italic header h5">
              <u>{Translate("contactTextWorkingHours")}</u>
            </p>
            <div className="col mt-1">
              <p className="m-0">
                <em>{Translate("inweek")}:</em> 09:30 - 19:00
              </p>
              <p className="m-0">
                <em>{Translate("saturday")}:</em> 09:30 - 18:00
              </p>
              <p className="m-0">
                <em>{Translate("sunday")}:</em>
                <b> {Translate("closed")}</b>
              </p>
            </div>
          </div>
        </div>

        <div className="col-sm-6 col-xs-12">
          <div className="row mb-4 justify-content-center text-center">
            <a
              className="addresslink fw-bolder"
              style={pStyle}
              href="https://goo.gl/maps/RpGXB4CoVvYyTaZj7"
            >
              İçerenköy Mahallesi, Çayır Caddesi, No:10/A 34752
              Ataşehir/İstanbul/Türkiye
            </a>
            <div className="embed-responsive embed-responsive-4by3 d-flex mt-1 justify-content-center">
              <iframe
                title="Duodent"
                className="embed-responsive-item googlemap"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5065.997588786577!2d29.09636902241698!3d40.9757831138487!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac713e5dc6823%3A0xdbbd62c74df0f190!2zRHVvZGVudCBBxJ_EsXogdmUgRGnFnyBTYcSfbMSxxJ_EsSBQb2xpa2xpbmnEn2k!5e0!3m2!1str!2sde!4v1692526068567!5m2!1str!2sde"
                width="400px"
                height="400px"
                allowFullScreen={undefined}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Communication;
