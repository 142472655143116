import "./header.css";
import NavigationBar from "../navbar/navbar";
import LanguageSwitcher from "./languageswitcher";

const Header = ({
  language,
  setLanguage,
}: {
  language: any;
  setLanguage: any;
}) => (
  <div className="container-fluid m-0 p-0">
    <div className="row d-flex m-0 p-0 justify-content-end">
      <div className="col-3 d-none p-0 m-0 d-lg-flex justify-content-center align-items-center">
        <div className="row m-0 p-0">
          <div className="col-9 p-0 m-0 justify-content-center align-content-center">
            <a className="col-10 nav-link" href="/home">
              <img
                src={"/icons/duodentlogo_360x95.webp"}
                className="img-fluid logo"
                alt="logo"
              />
            </a>
          </div>
          <div className="col-3 p-0 m-0 justify-content-center align-content-center">
            <LanguageSwitcher
              TextSize={16}
              language={language}
              setLanguage={setLanguage}
            ></LanguageSwitcher>
          </div>
        </div>
      </div>

      <div className="col-7 d-none p-0 m-0 d-lg-flex text-center justify-content-center align-items-center">
        <NavigationBar
          language={language}
          setLanguage={setLanguage}
        ></NavigationBar>
      </div>
      <div className="col-2 d-none p-0 m-0 d-lg-flex align-items-center justify-content-center">
        <SocialMediaIcons isMobile={false} />
      </div>
    </div>
    <div className="row d-flex d-lg-none m-0 p-0 justify-content-center align-items-center">
      <NavigationBar
        language={language}
        setLanguage={setLanguage}
        SocialMediaIcons={<SocialMediaIcons isMobile={true} />} // Add the isMobile prop
        Logo={<LogoMinimal />}
      ></NavigationBar>
    </div>
  </div>
);

const LogoMinimal = () => (
  <a className="nav-link" href="/home">
    <img
      src={"/icons/duodentlogo_360x95.webp"}
      className="img-fluid logo-min"
      alt="logo"
    />
  </a>
);

const SocialMediaIcons = ({ isMobile }: { isMobile: boolean }) => {
  const imageClassName = isMobile
    ? "img-fluid social-media-icons-mobile"
    : "img-fluid social-media-icons";

  return (
    <div className="row m-0 p-0 justify-content-center">
      <div className="col-2">
        <a href="https://wa.me/905057667386">
          <img
            className={imageClassName}
            src={"/icons/whatsapp_60x60.webp"}
            alt="whatsapp"
          />
        </a>
      </div>
      <div className="col-2">
        <a href="https://www.instagram.com/duodent.clinic/">
          <img
            className={imageClassName}
            src={"/icons/instagram_60x60.webp"}
            alt="instagram"
          />
        </a>
      </div>
      <div className="col-2">
        <a href="https://www.facebook.com/profile.php?id=100093738923168&mibextid=9R9pXO">
          <img
            className={imageClassName}
            src={"/icons/facebook_60x60.webp"}
            alt="facebook"
          />
        </a>
      </div>
      <div className="col-2">
        <a href="https://goo.gl/maps/RpGXB4CoVvYyTaZj7">
          <img
            className={imageClassName}
            src={"/icons/location_60x60.webp"}
            alt="location"
          />
        </a>
      </div>
      <div className="col-2">
        <a id="phonecalltrigger" href="tel:90-505-766-7386">
          <img
            id="phonecalltrigger"
            className={imageClassName}
            src={"/icons/phone_60x60.webp"}
            alt="phone"
          />
        </a>
      </div>
    </div>
  );
};

export default Header;
