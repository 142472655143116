import Translate from "../resources";
import "./team-member.css";

function TeamMember({
  name,
  title_1,
  title_2,
  instagramaddress,
  instagramaccount,
  resumeaddress,
  photo,
}: {
  name: string;
  title_1: string;
  title_2?: string;
  instagramaddress?: string;
  instagramaccount?: string;
  resumeaddress?: string;
  photo: string;
}) {
  var resumeaddr = "/team/" + resumeaddress;
  return (
    <div className="container-fluid mb-5 member-container align-items-center justify-content-center">
      <div className="row align-items-center justify-content-center">
        <img
          className="img rounded-pill member-image"
          src={photo}
          alt="member"
        ></img>
      </div>
      <div className="row">
        <p className="text-center member-name text-nowrap fw-bolder m-0">
          {name}
        </p>
      </div>
      <div className="row">
        <p className="text-center member-text text-nowrap fst-italic m-0">
          {title_1}
        </p>
      </div>
      <div className="row">
        <p className="text-center  member-text text-nowrap fst-italic m-0">
          {title_2}
        </p>
      </div>
      <div className="row text-center align-items-center justify-content-center">
        {resumeaddress !== undefined && (
          <div className="row align-items-center justify-content-center">
            <div className="col-auto m-0 p-0">
              <a href={resumeaddr}>
                <img
                  className="img-fluid shortcut-button"
                  src={"/icons/resume_40x40.webp"}
                  alt="instagram"
                />
              </a>
            </div>
            <div className="col-auto m-0 p-0">
              <p className="text-start  member-text text-nowrap fst-italic m-0">
                {Translate("resume")}
              </p>
            </div>
          </div>
        )}
        {instagramaddress !== undefined && (
          <div className="row align-items-center justify-content-center">
            <div className="col-auto m-0 p-0">
              <a href={instagramaddress}>
                <img
                  className="img-fluid shortcut-button"
                  src={"/icons/instagram_40x40.webp"}
                  alt="instagram"
                />
              </a>
            </div>
            <div className="col-auto m-0 p-0">
              <p className="text-start  member-text text-nowrap fst-italic m-0">
                {instagramaccount}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TeamMember;
