import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Communication from "./communication-page/communication";
import App from "./App/app";
import TeamPage from "./team/team";
import Home from "./home-page/home";
import ResumeGorkemGuner from "./resume-page/resume_gorkemguner";
import ResumeAyseSaatmanYildiz from "./resume-page/resume_aysesaatmanyildiz";
import ResumeEzgiKaya from "./resume-page/resume_ezgikaya";
import ResumeSelena from "./resume-page/resume_selena";
import TreatmentsPage from "./treatments-page/treatments";
import Gallery from "./gallery/gallery";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <BrowserRouter basename={baseUrl}>
    <Routes>
      <Route exact path="/" element={<App />}>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/treatments" element={<TreatmentsPage />} />
        <Route path="/team" element={<TeamPage />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/team/dtgorkemguner" element={<ResumeGorkemGuner />} />
        <Route
          path="/team/drdtaysesaatmanyildiz"
          element={<ResumeAyseSaatmanYildiz />}
        />
        <Route path="/team/dtezgikaya" element={<ResumeEzgiKaya />} />
        <Route path="/team/dtselenasudenizer" element={<ResumeSelena />} />
        <Route
          path="/communication"
          element={<Communication color="#846d57" />}
        />
      </Route>
    </Routes>
  </BrowserRouter>
);
