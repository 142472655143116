import "./resume.css";
import Translate from "../resources";

function Resume() {
  return (
    <div className="container-fluid">
      <div className="row mt-4 align-items-center justify-content-center">
        <img
          className="img-fluid rounded-circle resumeimage"
          src={`/team-photos/ayse_saatman_yildiz_300x300.webp`}
          alt="member"
          loading="lazy"
        ></img>
      </div>
      <p className=" resumeheader mt-2 text-center text-nowrap fw-bolder header">
        Dr. Dt. Ayşe Saatman Yıldız
      </p>
      <p className="text-center text-nowrap fst-italic resumecontent">
        {Translate("periodontology")} - {Translate("partner")}
      </p>
      <div className="row m-1">
        <p className=" resumecontent header">
          {Translate("resumeTextAyseYildizGeneral")}
        </p>
        <div className="row mt-2 mb-2">
          <p className=" resumeheader text-nowrap fw-bolder header">
            {Translate("resumeTextTreatments")}
          </p>
          <ul className="ms-5">
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizTreatment1")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizTreatment2")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizTreatment3")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizTreatment4")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizTreatment5")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizTreatment6")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizTreatment7")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizTreatment8")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizTreatment9")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizTreatment10")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizTreatment11")}</li>
            </p>
          </ul>
        </div>

        <div className="row mt-2 mb-2">
          <p className=" resumeheader text-nowrap fw-bolder header">
            {Translate("resumeTextAcedemic")}
          </p>
          <ul className="ms-5">
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizAcademic1")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizAcademic2")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizAcademic3")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizAcademic4")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizAcademic5")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizAcademic6")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizAcademic7")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizAcademic8")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizAcademic9")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizAcademic10")}</li>
            </p>
          </ul>
        </div>

        <div className="row mt-2 mb-2">
          <p className=" resumeheader text-nowrap fw-bolder header">
            {Translate("resumeTextSeminars")}
          </p>
          <ul className="ms-5">
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizSeminars1")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizSeminars2")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizSeminars3")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizSeminars4")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizSeminars5")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizSeminars6")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizSeminars7")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizSeminars8")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizSeminars9")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizSeminars10")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizSeminars11")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizSeminars12")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizSeminars13")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizSeminars14")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizSeminars15")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizSeminars16")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizSeminars17")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizSeminars18")}</li>
            </p>
            <p className="m-1 resumecontent">
              <li>{Translate("resumeTextAyseYildizSeminars19")}</li>
            </p>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Resume;
