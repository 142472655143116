import React, { useState, useEffect } from "react";
import { Card, Grid, CardMedia, CardContent, Typography } from "@mui/material";
import treatmentsData from "./treatments.json";
import { useTranslation } from "react-i18next";

interface Treatment {
  id: string;
  title: string;
  content: string;
  image?: string;
}

interface TreatmentGroup {
  title: string;
  elements: Treatment[];
}

interface TreatmentGroups {
  [key: string]: TreatmentGroup;
}

function TreatmentsPage() {
  const { t } = useTranslation();
  const [treatmentGroups, setTreatmentGroups] = useState<TreatmentGroups>(
    {} as TreatmentGroups
  );

  useEffect(() => {
    setTreatmentGroups(treatmentsData);
  }, []);

  return (
    <Grid spacing={10}>
      {Object.keys(treatmentGroups).map((key) => (
        <Grid
          item
          key={key}
          sx={{
            padding: 2,
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              padding: 1,
              color: "#846d57",
              fontWeight: "bold",
            }}
            className="treatmentHeader"
            component="h5"
            variant="h5"
          >
            {t(`${treatmentGroups[key].title}`)}
          </Typography>
          <div className="row m-2">
            {treatmentGroups[key].elements.map((element) => (
              <div className="col-12 col-xxl-6 mb-2 d-flex" key={element.id}>
                <Card
                  className="card"
                  sx={{
                    padding: 2,
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  {element.image ? (
                    <CardMedia
                      sx={{
                        maxWidth: 300,
                        minWidth: 300,
                      }}
                      component="img"
                      className="rounded rounded-5 border border-2"
                      image={`/treatments-images/${element.image}`}
                      alt={element.id}
                      loading="lazy"
                    />
                  ) : null}
                  <CardContent>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#846d57",
                      }}
                      component="h6"
                      variant="h6"
                    >
                      {t(`${element.title}`)}
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: "left",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: t(`${element.content}`),
                      }}
                    />
                  </CardContent>
                </Card>
              </div>
            ))}
          </div>
        </Grid>
      ))}
    </Grid>
  );
}

export default TreatmentsPage;
